/* AnswerModal.css */

.answer-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001; /* Make sure this is higher than other items if using z-index */
  }
  
  .answer-modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    min-width: 300px; /* Adjust as needed */
  }
  
  .answer-modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .answer-button, .decline-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 24px; /* Adjust as needed */
  }
  
  .answer-icon {
    color: green;
  }
  
  .decline-icon {
    color: red;
  }
  