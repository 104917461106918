/* CallingModal.css */

.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: auto; /* Adjust width as needed */
  max-width: 300px; /* Set a max-width */
}

.cancel-call-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: red; /* Red color for the cancel icon */
}

.cancel-icon {
  font-size: 20px; /* Adjust size as needed */
}


.modal-content {
    background: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    animation: appear 0.3s ease-out;
}
  
@keyframes appear {
    from {opacity: 0; transform: translateY(-50px);}
    to {opacity: 1; transform: translateY(0);}
  }
  

  