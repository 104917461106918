.chat-window {
    /* border: 1px solid #ccc; */
    width: 200px;
    height: 392px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .chat-header {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .messages-area {
    border: 1px solid black;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 300px;
    overflow-y: auto;
    
  }
  
  .message {
    margin: 5px 0;
    color: red; /* Hasitha's messages are red as seen in the image */
  }
  
  .input-area {
    display: flex;
    height: 55px;
  }
  
.input-area textarea {
    border: 1px solid black;
    flex-grow: 1;
    border-bottom-left-radius: 5px;
    resize: none; /* Prevents resizing of the textarea */
}
  
  .input-area button {
    padding: 5px;
  }

  .send-button {
    border: 1px solid black;
    border-bottom-right-radius: 5px;
    border-left: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer; /* Changes cursor to pointer on hover */
  }
  
  /* Hover effect for desktop */
  .send-button:hover {
    background-color: #e0e0e0; /* Light grey background on hover */
  }
  /* Add additional styling as needed */

  .messages-area {
    display: flex;
    flex-direction: column;
    /* ... other styles ... */
  }
  
  .message {
    position: relative;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
  }
  
  .user-message {
    align-self: flex-end;
    background-color: blue;
    color: white;
  }
  
  .other-message {
    align-self: flex-start;
    background-color: red;
    color: white;
  }
  
  /* Add this to handle overflow in messages-area */
  .messages-area {
    overflow-y: auto;
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* rest of your styles */
  }
  
  .phone-icon {
    font-size: 20px;
    color: black; /* Default color */
    cursor: pointer; /* Changes the cursor to the pointer hand when you hover over the icon */
  }
  
  .phone-icon:hover,
  .icon-clicked {
    color: green; /* Color when hovered or clicked */
  }
  
  .icon-clicked:hover {
    color: red; /* Color when the icon has been clicked and is then hovered again */
  }

  .call-timer {
    color: green;
    /* Add more styling as needed, e.g., font-weight, background, etc. */
  }

  .unpair-button {
    margin-top: 5px;
  }

  .unpair-button:hover {
    background-color: red;
    color: black;
    cursor: pointer;
  }


  .file-progress {
    height: 100%;
    background-color: #4caf50; /* Progress bar color */
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; /* Behind the text */
    transition: width 0.5s ease-in-out;
    border-radius: 10px;
  }
  
  .message-text {
    position: relative;
    z-index: 2; /* Ensures text is above the progress bar */
    /* Additional text styling if needed */
  }
  
  
  
  